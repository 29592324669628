import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap"
import mail from "../images/mail.svg"
import {
  ALERT_MSG_ERROR,
  ALERT_MSG_INFO,
  AUTH_TOKEN_KEY,
  FR,
  KEY_LANGUAGE_BROWSER,
  KEY_LANGUAGE_USER,
  translate,
  URL_CHANGE_PASSWORD
} from "../utils"
import gifFR from "../images/screen-fr.gif"
import gifNL from "../images/screen-nl.gif"
import padlockClosed from "../images/padlock-closed.svg"
import axios from "axios"
import ModalAlert from "../components/modalAlert"

const Recover = () => {
  const [placeholderPassword,setplaceholderPassword] = useState('');
  const [placeholderPasswordNew,setplaceholderPasswordNew] = useState('');
  const [password,setPassword] = useState('');
  const [passwordNew,setPasswordNew] = useState('');
  const [showAlert, setshowAlert] = useState(false);
  const [alertTitle, setalertTitle] = useState('');
  const [alertMessage, setalertMessage] = useState('');

  useEffect(() => {
    setplaceholderPassword(translate('oud wachtwoord'))
    setplaceholderPasswordNew(translate('nieuw wachtwoord'))
  },[])

  const pressChangePassword = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const header = {
      headers: { Authorization: `Bearer ${token}` },
    }
    const data = {
      password: password,
      newPassword: passwordNew
    }
    axios.post(URL_CHANGE_PASSWORD,data,header)
      .then(res => {
        setalertTitle(ALERT_MSG_INFO)
        setalertMessage(translate("uw wachtwoord is veranderd"))
        setshowAlert(true)
        localStorage.removeItem(AUTH_TOKEN_KEY);
        setTimeout(redirectToLoginPage,1500);
      })
      .catch(err => {
        if (err.response && err.response.data) {
          if (err.response.data === 'invalid credentials') {
            setalertTitle(ALERT_MSG_ERROR)
            setalertMessage(translate('invalid credentials'))
            setshowAlert(true)
          }else {
            setalertTitle(ALERT_MSG_ERROR)
            setalertMessage(err.response.data.message)
            setshowAlert(true)
          }
        }
        console.log(err)
      })
  }

  const redirectToLoginPage = () => {
    window.location.href='/login'
  }

  let isDisabled = false;
  if (passwordNew === password) isDisabled = true;
  if (passwordNew.length < 1) isDisabled = true;

  return (
    <Layout withBg>
      <div className="formWrapper">
        <Container>
          <Row className="justify-content-center">
            <Col lg="4">
              <div className="text-left">
                <h2 className="mb-4">{translate('reset mijn wachtwoord')}</h2>
                <Form onSubmit={e => pressChangePassword(e)}>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        <img
                          width={11}
                          height={17}
                          className="img-fluid"
                          src={padlockClosed}
                          alt=""
                        />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type={'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder={placeholderPassword}
                      aria-label=""
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        <img
                          width={11}
                          height={17}
                          className="img-fluid"
                          src={padlockClosed}
                          alt=""
                        />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type={'password'}
                      value={passwordNew}
                      onChange={(e) => setPasswordNew(e.target.value)}
                      placeholder={placeholderPasswordNew}
                      aria-label=""
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>

                  {/*<InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">
                        <img
                          width={16}
                          height={8}
                          className="img-fluid"
                          src={mail}
                          alt="mail"
                        />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      placeholder={placeholderEmail}
                      aria-label=""
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>*/}
                  <Button disabled={isDisabled} variant="success" type="submit" className="button-yellow ">
                    {translate('bevestigen')}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {(showAlert)?
        <ModalAlert
          alertTitle={alertTitle}
          alertMessage={alertMessage}
          closeModal={() => setshowAlert(false)}
        />:null
      }
    </Layout>
  )
}

export default Recover
